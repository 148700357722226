import React from 'react';
import './PopularItem.css';

export default function PopularItem({title, desc, img}) {
  return (
    <div className='popular-item'>
        <img className='popular-item-img' 
            src={img} 
            alt={title} 
        />
        <h4 className='popular-item-heading'>{title}</h4>
        <p className='popular-item-description'>{desc}</p>
    </div>
  )
}
