import img1 from '../../../../assets/home_gallery/z1.jpg';
import img2 from '../../../../assets/home_gallery/z2.jpg';
import img3 from '../../../../assets/home_gallery/z3.jpg';
import img4 from '../../../../assets/home_gallery/z4.jpg';
import img5 from '../../../../assets/home_gallery/z5.jpg';
import img6 from '../../../../assets/home_gallery/z6.jpg';
import img7 from '../../../../assets/home_gallery/z7.jpg';
import img8 from '../../../../assets/home_gallery/z8.jpg';
export const Imageitems = [
    {
        alt : "Koloryzacja włosów",
        url : img1
    },
    {
        alt : "Koloryzacja włosów",
        url : img2
    },
    {
        alt : "Koloryzacja włosów",
        url : img3
    },
    {
        alt : "Koloryzacja włosów",
        url : img4
    },
    {
        alt : "Koloryzacja włosów",
        url : img5
    },
    {
        alt : "Koloryzacja włosów",
        url : img6
    },
    {
        alt : "Koloryzacja włosów",
        url : img7
    },
    {
        alt : "Koloryzacja włosów",
        url : img8
    }
]