import React from 'react';
import './Categories.css';
import { Categoryitems } from './CategoryItems/CategoryItems';
import Category from './Category/Category';

export default function Categories() {
  return (
    <div className='categories-wrapper'>
        {Categoryitems.map((item,index) =>(
          <Category title={item.title} img={item.img} url={item.url} key={index}/>
        ))}
    </div>
  )
}
