import React from 'react'
import { Slideritems } from './SliderItems/SliderItems'
import './ImageSlider.css';

export default function ImageSlider() {
  return (
    <div className='image-slider-inner'>
        {Slideritems.map((item, index) => (
            <img 
                className='slider-item' 
                src={item.url} 
                alt={item.alt} 
                key={index} 
            />
        ))}
    </div>
  )
}
