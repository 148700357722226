import { faTiktok, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
export const links = [
    { 
        icon: faFacebookF, 
        url: 'https://www.facebook.com/SalonFryzjerskiMariza' },
    { 
        icon: faInstagram, 
        url: 'https://www.instagram.com/salonfryzjerskimariza/' },
    { 
        icon: faTiktok, 
        url: 'https://www.tiktok.com/@salonmariza' 
    }
];