import React from 'react';
import './ImageGallery.css';
import { Imageitems } from './ImageItems/ImageItems';
import ImageItem from './ImageItem/ImageItem';


export default function ImageGallery() {
  return (
    <div className='image-gallery-wrapper'>
        <div className="image-gallery-header">
            <h5>Galeria naszych realizacji</h5>
            <a href="/gallery">Wszystkie zdjęcia</a>
        </div>
        <div className="image-gallery-inner">
            {Imageitems.map((item, index) => (
                <ImageItem imgUrl={item.url} alt={item.alt} key={index}/>
            ))}
        </div>
    </div>
  )
}
