import React from 'react';
import { Menuitems } from '../MenuItems/MenuItems';
import NavLink from './NavLink/NavLink';
import './NavBar.css';

export default function NavBar() {
  return (
    <nav className='nav-bar'>
        <ul className='nav-links'>
            {Menuitems.map((MenuItem, index) => (
                <NavLink 
                    title={MenuItem.title} 
                    link={MenuItem.url} 
                    key={index} 
                />
            ))}
        </ul>
    </nav>
  )
}
