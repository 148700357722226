import { faScissors, faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
export const Aboutitems = [
    {
        icon:  faThumbsUp,
        title: "Doświadczenie",
        description : "Nasz salon to miejsce, gdzie doświadczenie spotyka się z pasją. Pracujemy na rynku ponad 30 lat co gwarantuje, że nasz zespół jest gotowy obsłużyć Cię na najwyższym poziomie.",
    },
    {
        icon : faScissors,
        title: "Fryzjerstwo",
        description : "Nasz zespół fryzjerów i stylistów w Salonie Fryzjerskim Mariza tworzy unikalne fryzury, łącząc nowoczesne trendy z klasyczną elegancją.",
    },
    {
        icon: faStar,
        title: "Profesjonalizm",
        description : "Salon Fryzjerski Mariza należy do Grupy Salonów Expert Loreal Professional. Nasz wykwalifikowany personel pod nadzorem Marii Mondry zapewnia usługi na najwyższym poziomie.",
    }
]