import React from 'react';
import './About.css';
import { Aboutitems } from './AboutItems/AboutItems';
import AboutItem from './AboutItem/AboutItem';

export default function About() {
  return (
    <div className='about-section-wrapper' id='about-section-wrapper'>
        <div className="about-section-inner">
            {Aboutitems.map((item, index) => (
                <AboutItem 
                  icon={item.icon} 
                  title={item.title} 
                  description={item.description} 
                  key={index}
                />
            ))}
        </div>
    </div>
  )
}
