import React from 'react';
import './NavLink.css';

export default function NavLink({title, link}) {
  return (
    <li className='nav-link'>
        <a className="link" href={link}>{title}</a>
    </li>
  )
}
