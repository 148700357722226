import React from 'react';
import './AboutItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AboutItem({icon, title, description}) {
  return (
    <div className='about-item'>
        <FontAwesomeIcon className='about-item-icon' icon={icon}/>
        <h6>{title}</h6>
        <p>{description}</p>
    </div>
  )
}
