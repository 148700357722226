import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import Categories from './Categories/Categories';
import Popular from './Popular/Popular';
import ImageGallery from './ImageGallery/ImageGallery';
import About from './About/About';
import LorealBanner from './LorealBanner/LorealBanner';

export default function Home() {
  return (
    <>
        <HeroSection/>
        <Categories/>
        <Popular/>
        <About/>
        <ImageGallery/>
        <LorealBanner/>
    </>
  )
}
