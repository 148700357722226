import React from 'react';
import './Contactitem.css';

export default function ContactItem({title, description}) {
  return (
    <div className="contact-item">
        <h1>{title}</h1>
        <ul>
            {description.map((desc, index) => (
                <li key={index}>{desc}</li>
            ))}
        </ul>
    </div>
  )
}
