import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function HeroLink({ icon, url }) {
  return (
    <Link to={url} className='hero-link'>
      <FontAwesomeIcon icon={icon} />
    </Link>
  );
}

export default HeroLink;