import React from 'react';
import './HeroSection.css';
import ImageSlider from './ImageSlider/ImageSlider';
import HeroLink from './HeroLink/HeroLink';
import { links } from './HeroItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function HeroSection() {

    return (
    <div className='hero-wrapper'>
        <div className='hero-image-slider'>
            <ImageSlider/>
            <div className="hero-info-section">
                <h2>A czy ty jesteś gotowy na nową wersję siebie?</h2>
                <a className='hero-info-link' href="/reservation">Umów się na wizytę</a>
                <div className='hero-info-links'>
                    {links.map((link, index) => (
                        <HeroLink key={index} icon={link.icon} url={link.url} />
                    ))}
                </div>
            </div>
            <a href='#about-section-wrapper' className='scroll-down-arrow'>
                <FontAwesomeIcon icon={faCaretDown}/>
            </a>
        </div>
    </div>
  )
}
