import React, { useEffect, useState } from 'react';
import PageHeroSection from '../../Components/PageHeroSection/PageHeroSection';
import './Offer.css';
import Offeritems from './OfferItems/OfferItems.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFemale, faMale, faChild } from '@fortawesome/free-solid-svg-icons';

export default function Offer() {
  const [activeOffer, setActiveOffer] = useState('A');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('?type=man')) {
      setActiveOffer('M');
    }
    if (currentUrl.includes('?type=woman')) {
      setActiveOffer('W');
    }
  }, []);

  useEffect(() => {
    const itemArray = Offeritems.Offeritems.filter(item => {
      return activeOffer === 'A' ? true : item.type === activeOffer;
    });
    setFilteredItems(itemArray);
  }, [activeOffer]);

  function setOfferIcon(char){
    switch(char){
      case 'W': return <FontAwesomeIcon icon={faFemale} />;
      case 'M': return <FontAwesomeIcon icon={faMale} />;
      case 'C': return <FontAwesomeIcon icon={faChild} />;
      default:
        break;
    }
  }

  return (
    <>
      <PageHeroSection title={"Oferta"}/>
      <div className="offer-select-section">
        <button className={`offer-select-button ${activeOffer === 'A' ? "active" : ''}`} onClick={() => setActiveOffer('A')}>Wszystko</button>
        <button className={`offer-select-button ${activeOffer === 'W' ? "active" : ''}`} onClick={() => setActiveOffer('W')}>Kobiety</button>
        <button className={`offer-select-button ${activeOffer === 'M' ? "active" : ''}`} onClick={() => setActiveOffer('M')}>Mężczyźni</button>
        <button className={`offer-select-button ${activeOffer === 'C' ? "active" : ''}`} onClick={() => setActiveOffer('C')}>Dzieci</button>
      </div>
      <table className='offer-table-container'>
        <tbody className='offer-tbody'>
            <tr className='offer-tr'>
              <th className='offer-type-container-header'>Typ</th><th className='offer-name-container-header'>Nazwa</th><th className='offer-price-container-header'>Cena</th>
            </tr>
          {filteredItems.map((item, index) => (
            <tr className='offer-tr' key={index}>
              <td className='offer-type-container'>{setOfferIcon(item.type)}</td><td className='offer-name-container'>{item.name}</td><td className='offer-price-container'>{item.price} zł</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
