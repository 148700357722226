import React from 'react';
import './LorealBanner.css';
import lorealLogo from '../../../assets/home_loreal_banner/lorealLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function LorealBanner() {
  return (
    <div className='loreal-banner-wrapper'>
        <div className="loreal-banner-inner">
            <img src={lorealLogo} alt="LOréal SA" className="loreal-logo" />
            <div className="loreal-banner-description">
                <h6>BECAUSE YOU'RE WORTH IT!</h6>
                <p>Przeglądaj stylizacje, i zadbaj o piękno i zdrowie Twoich włosów z Loreal Polska.</p>
            </div>
            <div className="loreal-banner-link-container">
                <a href="https://www.lorealparis.pl/">Loreal Paris <FontAwesomeIcon icon={faArrowRight}/></a>
            </div>
        </div>
    </div>
  )
}
