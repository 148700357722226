import React from 'react';
import './GalleryItem.css'

export default function GalleryItem({imgUrl, altText, openImagePreview}) {
  return (
    <div className='gallery-item' onClick={openImagePreview}>
        <img src={imgUrl} alt={altText} />
    </div>
  )
}
