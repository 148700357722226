export const Menuitems = [
    {
        title : "Oferta",
        url : "/offer"
    },
    {
        title : "Galeria",
        url : "/gallery"
    },
    {
        title : "Rezerwacja",
        url : "/reservation"
    },
    {
        title : "Kontakt",
        url : "/contact"
    }
]