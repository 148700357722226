import React from 'react';
import './Footer.css';
import { ContactData } from '../ContactData/ContactData';

export default function Footer() {
  return (
    <footer className='footer-wrapper'>
        <div className="footer-container">
            <div className="footer-content">
                <h4>Salon Mariza &copy;</h4>
                <ul>
                    <li>Zapraszamy do kontaktu pod wskazanym numerem telefonu.</li>
                    <li>Salon Mariza | Expert L'Oréal Proffesional Polska</li>
                </ul>
            </div>
            <div className="footer-content">
                <h4>Dane kontaktowe</h4>
                <ul>
                    <li>{ContactData.zip}</li>
                    <li>Adres: {ContactData.address}</li>
                    <li>Tel: {ContactData.phone}</li>
                    <li>Email: {ContactData.email}</li>
                </ul>
            </div>
            <div className="footer-content">
                <h4>Nawigacja</h4>
                <ul>
                    <li><a href="/offer">Oferta</a></li>
                    <li><a href="/gallery">Galeria</a></li>
                    <li><a href="/reservation">Rezerwacja</a></li>
                    <li><a href="/contact">Kontakt</a></li>
                </ul>
            </div>
            <div className="footer-content">
                <h4>Socialmedia</h4>
                <ul>
                    <li><a href="/">Salon Mariza</a></li>
                    <li><a href="https://www.facebook.com/SalonFryzjerskiMariza">Facebook</a></li>
                    <li><a href="https://www.instagram.com/salonfryzjerskimariza/">Instagram</a></li>
                    <li><a href="https://www.tiktok.com/@salonmariza">TikTok</a></li>
                </ul>
            </div>
        </div>
    </footer>
  )
}
