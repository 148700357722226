import React from 'react';
import './ImagePreview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function ImagePreview({imgUrl, altText, closeImagePreview}) {
  return (
    <div className='image-preview-wrapper'>
        <div className="close-btn" onClick={(e) => closeImagePreview(e)}><FontAwesomeIcon icon={faTimes} /></div>
        <img className='image-preview' src={imgUrl} alt={altText}/>
    </div>
  )
}
