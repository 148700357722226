import React, { useEffect, useState } from 'react';
import './Header.css';
import NavBar from './NavBar/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

export default function Header() {
  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleMenuClick = () => {
    toggleMenuOpen(!isMenuOpen);
  }

  const updateIsMobile = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return (
    <header className='header'>
        <a href="/"><h1 className='logo-container'>Salon Mariza</h1></a>
        {isMenuOpen || !isMobile ? (<NavBar/>) : (<></>)}
          {isMobile ? (          
          <button className="menu-button">
            <FontAwesomeIcon 
              onClick={handleMenuClick} 
              icon={isMenuOpen ? faTimes : faBars}
            />
          </button>
          ) : (<></>)}
    </header>
  )
}
