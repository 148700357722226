import imageBanner1 from '../../../../../assets/home_slider/1.jpg';
import imageBanner2 from '../../../../../assets/home_slider/2.png';
import imageBanner3 from '../../../../../assets/home_slider/3.jpg';
import imageBanner4 from '../../../../../assets/home_slider/4.jpg';
export const Slideritems = [
    {
        alt : "Banner image 1",
        url : imageBanner1
    },
    {
        alt : "Banner image 2",
        url : imageBanner2
    },
    {
        alt : "Banner image 3",
        url : imageBanner3
    },
    {
        alt : "Banner image 4",
        url : imageBanner4
    }
]