import React from 'react';
import PageHeroSection from '../../Components/PageHeroSection/PageHeroSection';
import './Contact.css';
import { Contactitems } from './ContactItems/ContactItems';
import ContactItem from './ContactItem/ContactItem';

export default function Contact() {
  return (
    <>
      <PageHeroSection title={"Kontakt"}/>
      <div className="contact-section-wrapper">
          {Contactitems.map((item, index) => (
            <ContactItem title={item.title} description={item.description} key={index}/>
          ))}
      </div>
    </>
  )
}
