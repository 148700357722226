import colorImg from '../../../../assets/home_popular/color.png';
import cutImg from '../../../../assets/home_popular/cut.png';
import stylingImg from '../../../../assets/home_popular/styling.png';
export const PopularItems = [
    {
        title : "Farbowanie",
        image : colorImg,
        description : "Oferujemy usługi farbowania i stylizacji włosów profesjonalnymi produktami znanych marek takich jak Loreal i INOA"
    },
    {
        title : "Ścinanie",
        image : cutImg,
        description : "Nasza wykwalifikowana i doświadczona kadra fryzjerów stanowi gwarancję starannego i profesjonalnego wykonania strzyżenia."
    },
    {
        title : "Stylizacje",
        image : stylingImg,
        description : "Profesjonalni fryzjerzy nie tylko wysłuchaj a preferencji klientów, ale także doradzą optymalne stylizacje"
    }
]