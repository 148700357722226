import React, { useState } from 'react';
import PageHeroSection from '../../Components/PageHeroSection/PageHeroSection';
import './Gallery.css';
import { Galleryitems } from './GalleryItems/GalleryItems';
import GalleryItem from './GalleryItem/GalleryItem';
import ImagePreview from './ImagePreview/ImagePreview';

export default function Gallery() {
  const [previewImage,setPreviewImage] = useState(false);
  const [previewImageUrl,setPreviewImageUrl] = useState("");

  const handleClick = (e) =>{
    setPreviewImage(!previewImage);
    setPreviewImageUrl(e.target.src);
  }

  return (
    <>
      <PageHeroSection title={"Galeria"}/>
      <div className="gallery-section-wrapper">
        {Galleryitems.map((item, index) => (
          <GalleryItem 
            imgUrl={item.url} 
            altText={item.alt} 
            key={index} 
            openImagePreview={(e) => handleClick(e)
            }
          />
        ))}
      </div>
      {previewImage ? 
        (<ImagePreview 
          imgUrl={previewImageUrl} 
          altText={""} 
          closeImagePreview={() => setPreviewImage(!previewImage)}/>) 
          : 
        ("")
      }
    </> 
  )
}
