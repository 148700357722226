import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Routes/Home/Home';
import Offer from './Routes/Offer/Offer';
import Gallery from './Routes/Gallery/Gallery';
import Reservation from './Routes/Reservation/Reservation';
import Contact from './Routes/Contact/Contact';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <>
      <Header/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/offer" element={<Offer/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/reservation" element={<Reservation/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
