import React, { useState } from 'react'
import PageHeroSection from '../../Components/PageHeroSection/PageHeroSection';
import './Reservation.css';
import reservationBanner from '../../assets/reservation_images/model.jpeg';
import salonExpertLogo from '../../assets/reservation_images/salonExpert.jpg';
export default function Reservation() {
  const [isVisible, toggleVisibility] = useState(false);

  return (
    <>
      <PageHeroSection title={"Rezerwacja"}/>
      <div className="reservation-section-wrapper">
        <img className='reservation-section-banner' src={reservationBanner} alt="Salon fryzjerski Mariza" />
        <div className="reservation-description-container">
          <img className='salon-expert-logo' src={salonExpertLogo} alt="Salon Expert Loreal Professionnel Paris" />
          <h1>Salon Mariza</h1>
          <p>Umów się na wizytę i skorzystaj z naszych usług w wygodnym dla Ciebie terminie.</p>
          <button onClick={() => toggleVisibility(!isVisible)}>
            {!isVisible ? ("Telefon") : ("+48 698 883 479")}
          </button>
        </div>
      </div>
    </>
  )
}
