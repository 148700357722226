import React from 'react';
import './Category.css';
export default function Category({title, img, url}) {
  return (
    <a className='category-link' href={url}>
        <div className='category-container'>
            <img src={img} alt={title} />
            <h3 className='category-title'>{title}</h3>
        </div>
    </a>
  )
}
