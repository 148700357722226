import manImg from '../../../../assets/home_categories/man.png';
import womanImg from '../../../../assets/home_categories/woman.jpg'
export const Categoryitems = [
    {
        title : "Zabiegi dla mężczyzn",
        img : manImg,
        url : "/offer?type=man"
    },
    {
        title : "Zabiegi dla kobiet",
        img : womanImg,
        url : "/offer?type=woman"
    }
]