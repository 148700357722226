import React from 'react';
import './Popular.css';
import { PopularItems } from './PopularItems/PopularItems';
import PopularItem from './PopularItem/PopularItem';

export default function Popular() {
  return (
    <div className="popular-services-wrapper">
      <h1>Najpopularniejsze usługi</h1>
      <div className="popular-services-inner">
        {PopularItems.map((item, index) => (
          <PopularItem 
            title={item.title} 
            desc={item.description} 
            img={item.image}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}
