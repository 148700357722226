import { ContactData } from "../../../Components/ContactData/ContactData"
export const Contactitems = [
    {
        title : "Dane kontaktowe",
        description : [ContactData.phone,ContactData.email]
    },
    {
        title : "Godziny otwarcia",
        description : ["Poniedziałek: 08:00–19:00", "Wtorek: 08:00–19:00", "Środa: 08:00–19:00", "Czwartek: 08:00–19:00", "Piątek: 08:00–19:00", "Sobota: 07:00–15:00"]
    },
    {
        title : "Adres",
        description : [ContactData.zip,ContactData.address]
    }
]